import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

const services = [
    { name: 'Authentication API', status: 'operational', responseTime: '45ms' },
    { name: 'Data Processing API', status: 'operational', responseTime: '350ms' },
    { name: 'Notification Service', status: 'operational', responseTime: '80ms' },
    { name: 'File Storage API', status: 'operational', responseTime: '45ms' },
    { name: 'Analytics API', status: 'operational', responseTime: '120ms' },
];

const getStatusIcon = (status) => {
    switch (status) {
        case 'operational':
            return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
        case 'degraded':
            return <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />;
        case 'down':
            return <XCircleIcon className="h-5 w-5 text-red-500" />;
        default:
            return null;
    }
};

const getStatusClass = (status) => {
    switch (status) {
        case 'operational':
            return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100';
        case 'degraded':
            return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100';
        case 'down':
            return 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100';
        default:
            return '';
    }
};

function APIStatus() {
    return (
        <div className="bg-white dark:bg-gray-900 min-h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    API Status
                </h1>
                <p className="mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-300 sm:mt-4">
                    Current status of Arrow Cloud Tech API services
                </p>

                <div className="mt-10">
                    <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-md">
                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                            {services.map((service) => (
                                <li key={service.name}>
                                    <div className="px-4 py-4 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                {getStatusIcon(service.status)}
                                                <p className="ml-3 text-sm font-medium text-gray-900 dark:text-white">
                                                    {service.name}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusClass(service.status)}`}>
                                                    {service.status}
                                                </span>
                                                <p className="ml-3 text-sm text-gray-500 dark:text-gray-300">
                                                    Response time: {service.responseTime}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="mt-10">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Incident History</h2>
                    <div className="mt-4 bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-md">
                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                            <li>
                                <div className="px-4 py-4 sm:px-6">
                                    <p className="text-sm font-medium text-gray-900 dark:text-white">March 15, 2024 - Data Processing API Degraded Performance</p>
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">Resolved: The Data Processing API experienced slower response times due to increased load. The issue has been resolved by scaling up our infrastructure.</p>
                                </div>
                            </li>
                            <li>
                                <div className="px-4 py-4 sm:px-6">
                                    <p className="text-sm font-medium text-gray-900 dark:text-white">February 28, 2024 - File Storage API Outage</p>
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">Resolved: The File Storage API was unavailable for 45 minutes due to a network issue. Our team has implemented additional redundancy to prevent similar incidents in the future.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="mt-10 text-center">
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Last updated: {new Date().toLocaleString()}
                    </p>
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                        For real-time updates, follow us on <a href="#" className="text-blue-500 hover:text-blue-600">Twitter</a>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default APIStatus;