import React from 'react';
import { CloudIcon, LockClosedIcon, ChartBarIcon, CogIcon } from '@heroicons/react/24/outline';

const features = [
    {
        name: 'Scalable Infrastructure',
        description: 'Easily scale your applications and services to meet growing demands.',
        icon: CloudIcon,
    },
    {
        name: 'Advanced Security',
        description: 'Protect your data with state-of-the-art security measures and compliance standards.',
        icon: LockClosedIcon,
    },
    {
        name: 'Performance Monitoring',
        description: 'Real-time monitoring and analytics to ensure optimal performance.',
        icon: ChartBarIcon,
    },
    {
        name: 'Automated Management',
        description: 'Streamline operations with automated deployment and management tools.',
        icon: CogIcon,
    },
];

function CloudSolutions() {
    return (
        <div className="bg-white dark:bg-gray-900 min-h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                        Cloud Solutions
                    </h1>
                    <p className="mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-300 lg:mx-auto">
                        Empower your business with our cutting-edge cloud technologies and services.
                    </p>
                </div>

                <div className="mt-16">
                    <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">{feature.name}</p>
                                <p className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-20">
                    <h2 className="text-2xl font-extrabold text-gray-900 dark:text-white">Our Cloud Solutions</h2>
                    <div className="mt-10 space-y-10">
                        <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:p-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                    Cloud Hosting
                                </h3>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                    Reliable and high-performance hosting solutions for your applications and websites.
                                </p>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:p-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                    Data Storage and Management
                                </h3>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                    Secure and scalable storage solutions with advanced data management capabilities.
                                </p>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:p-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                    Cloud Migration Services
                                </h3>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                    Seamlessly migrate your existing infrastructure and applications to our cloud platform.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-20 text-center">
                    <h2 className="text-2xl font-extrabold text-gray-900 dark:text-white">Ready to Get Started?</h2>
                    <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                        Contact our team to learn more about how our cloud solutions can benefit your business.
                    </p>
                    <div className="mt-8">
                        <a
                            href="/contact"
                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                        >
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CloudSolutions;