import React from 'react';

function Privacy() {
    return (
        <div className="bg-white dark:bg-gray-900 min-h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    Privacy Policy
                </h1>
                <p className="mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-300 sm:mt-4">
                    Last updated: March 1, 2024
                </p>

                <div className="mt-10 prose dark:prose-invert max-w-none">
                    <h2>1. Introduction</h2>
                    <p>
                        Arrow Cloud Tech ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services.
                    </p>

                    <h2>2. Information We Collect</h2>
                    <p>
                        We collect information that you provide directly to us, such as when you create an account, use our services, or contact us for support. This may include:
                    </p>
                    <ul>
                        <li>Name and contact information</li>
                        <li>Account credentials</li>
                        <li>Payment information</li>
                        <li>Usage data and analytics</li>
                    </ul>

                    <h2>3. How We Use Your Information</h2>
                    <p>
                        We use the information we collect to:
                    </p>
                    <ul>
                        <li>Provide, maintain, and improve our services</li>
                        <li>Process transactions and send related information</li>
                        <li>Send technical notices, updates, and support messages</li>
                        <li>Respond to your comments and questions</li>
                        <li>Analyze usage patterns and trends</li>
                    </ul>

                    <h2>4. Data Sharing and Disclosure</h2>
                    <p>
                        We do not sell your personal information. We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.
                    </p>

                    <h2>5. Data Security</h2>
                    <p>
                        We implement appropriate technical and organizational measures to protect the security of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
                    </p>

                    <h2>6. Your Rights and Choices</h2>
                    <p>
                        You have the right to access, correct, or delete your personal information. You may also have the right to object to or restrict certain processing of your data.
                    </p>

                    <h2>7. Changes to This Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
                    </p>

                    <h2>8. Contact Us</h2>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at privacy@arrowcloud.tech.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Privacy;