import React from 'react';
import { Link } from 'react-router-dom';
import { BriefcaseIcon } from '@heroicons/react/24/outline';

const jobOpenings = [
    {
        title: 'Full Stack Developer',
        experience: '2+ years',
        requirements: [
            'Proficiency in React, Node.js, and MongoDB',
            'Experience with RESTful APIs and GraphQL',
            'Familiarity with cloud platforms (AWS, Azure, or GCP)',
            'Strong problem-solving and communication skills',
        ],
    },
    {
        title: 'Business Development Manager',
        experience: '2+ years',
        requirements: [
            'Proven track record in B2B sales, preferably in the tech industry',
            'Strong networking and relationship-building skills',
            'Excellent presentation and negotiation abilities',
            'Understanding of SaaS and digital marketing landscapes',
        ],
    },
    {
        title: 'Software Developer Intern',
        experience: 'Entry-level',
        requirements: [
            'Currently pursuing a degree in Computer Science or related field',
            'Basic knowledge of web development technologies (HTML, CSS, JavaScript)',
            'Eagerness to learn and work in a fast-paced environment',
            'Strong problem-solving skills and attention to detail',
        ],
    },
];

function Jobs() {
    return (
        <div className="bg-white dark:bg-gray-900">
            {/* Hero section */}
            <div className="bg-gradient-to-r from-blue-500 to-blue-700 dark:from-blue-700 dark:to-blue-900">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h1 className="text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
                            Join Our Team
                        </h1>
                        <p className="mt-6 max-w-2xl mx-auto text-xl text-blue-100">
                            Explore exciting career opportunities at Arrow Cloud Tech
                        </p>
                    </div>
                </div>
            </div>

            {/* Job listings section */}
            <div className="py-24 bg-white dark:bg-gray-800">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center mb-16">
                        <h2 className="text-base text-blue-600 dark:text-blue-400 font-semibold tracking-wide uppercase">Careers</h2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                            Current Job Openings
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 dark:text-gray-300 lg:mx-auto">
                            Join our team of innovators and make an impact in the world of technology.
                        </p>
                    </div>

                    <div className="space-y-12">
                        {jobOpenings.map((job, index) => (
                            <div key={index} className="bg-white dark:bg-gray-700 shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6 flex items-center">
                                    <BriefcaseIcon className="h-6 w-6 text-blue-500 mr-3" />
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">{job.title}</h3>
                                </div>
                                <div className="border-t border-gray-200 dark:border-gray-600 px-4 py-5 sm:p-0">
                                    <dl className="sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-600">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Experience</dt>
                                            <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">{job.experience}</dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Requirements</dt>
                                            <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                                                <ul className="list-disc pl-5 space-y-1">
                                                    {job.requirements.map((req, reqIndex) => (
                                                        <li key={reqIndex}>{req}</li>
                                                    ))}
                                                </ul>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 dark:bg-gray-600 text-right sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="bg-blue-50 dark:bg-blue-900">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white md:text-4xl">
                        <span className="block">Don't see a perfect fit?</span>
                        <span className="block text-blue-600 dark:text-blue-400">We're always looking for talented individuals.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <Link
                                to="/contact"
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                            >
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Ensure proper spacing at the bottom */}
            <div className="py-12 bg-white dark:bg-gray-900"></div>
        </div>
    );
}

export default Jobs;