import React from 'react';

const partners = [
    { id: 1, name: 'TechCorp Solutions', logo: '/logos/techcorp.png', description: 'Leading provider of enterprise software solutions.' },
    { id: 2, name: 'CloudNet Systems', logo: '/logos/cloudnet.png', description: 'Innovative cloud infrastructure and networking services.' },
    { id: 3, name: 'DataSphere Analytics', logo: '/logos/datasphere.png', description: 'Advanced data analytics and machine learning platforms.' },
    { id: 4, name: 'SecureGuard', logo: '/logos/secureguard.png', description: 'Cutting-edge cybersecurity and threat detection solutions.' },
];

function Partners() {
    return (
        <div className="bg-white dark:bg-gray-900 min-h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    Our Partners
                </h1>
                <p className="mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-300 sm:mt-4">
                    Collaborating with industry leaders to deliver exceptional solutions.
                </p>

                <div className="mt-10 grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                    {partners.map((partner) => (
                        <div key={partner.id} className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:p-6">
                                <img src={partner.logo} alt={partner.name} className="h-12 w-auto mb-4" />
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                    {partner.name}
                                </h3>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                    {partner.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-16 text-center">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Become a Partner</h2>
                    <p className="mt-4 text-base text-gray-700 dark:text-gray-300">
                        Interested in partnering with Arrow Cloud Tech? We're always looking for innovative companies to collaborate with.
                    </p>
                    <a
                        href="#"
                        className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Contact Us About Partnerships
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Partners;