import React from 'react';

const pressReleases = [
    {
        id: 1,
        title: 'Arrow Cloud Tech Launches Revolutionary SaaS Platform',
        date: 'March 15, 2024',
        excerpt: 'Arrow Cloud Tech introduces a groundbreaking SaaS platform designed to streamline business operations.',
    },
    {
        id: 2,
        title: 'Arrow Cloud Tech Secures $50 Million in Series B Funding',
        date: 'February 1, 2024',
        excerpt: 'The recent funding round will accelerate product development and international expansion.',
    },
    {
        id: 3,
        title: 'Arrow Cloud Tech Named "Top Emerging Company" by Tech Innovators',
        date: 'December 10, 2023',
        excerpt: 'The prestigious award recognizes Arrow Cloud Tech contributions to the SaaS industry.',
    },
];

function Press() {
    return (
        <div className="bg-white dark:bg-gray-900 min-h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    Press Releases
                </h1>
                <p className="mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-300 sm:mt-4">
                    Stay updated with the latest news and announcements from Arrow Cloud Tech.
                </p>

                <div className="mt-10 space-y-10">
                    {pressReleases.map((release) => (
                        <div key={release.id} className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                    {release.title}
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-300">
                                    {release.date}
                                </p>
                            </div>
                            <div className="border-t border-gray-200 dark:border-gray-700 px-4 py-5 sm:px-6">
                                <p className="text-base text-gray-700 dark:text-gray-300">
                                    {release.excerpt}
                                </p>
                                <div className="mt-4">
                                    <a href="#" className="text-base font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                                        Read full press release <span aria-hidden="true">&rarr;</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-10">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Media Contact</h2>
                    <p className="mt-4 text-base text-gray-700 dark:text-gray-300">
                        For press inquiries, please contact:<br />
                        Jane Doe, PR Manager<br />
                        Email: press@arrowcloud.tech<br />
                        Phone: (123) 456-7890
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Press;