import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    { name: 'Jan', users: 4000, revenue: 2400 },
    { name: 'Feb', users: 3000, revenue: 1398 },
    { name: 'Mar', users: 2000, revenue: 9800 },
    { name: 'Apr', users: 2780, revenue: 3908 },
    { name: 'May', users: 1890, revenue: 4800 },
    { name: 'Jun', users: 2390, revenue: 3800 },
];

function Analytics() {
    return (
        <div className="bg-white dark:bg-gray-900 min-h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    Analytics Dashboard
                </h1>
                <p className="mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-300 sm:mt-4">
                    Track your application's performance and user engagement.
                </p>

                <div className="mt-10">
                    <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">User Growth and Revenue</h2>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                                <Tooltip />
                                <Legend />
                                <Bar yAxisId="left" dataKey="users" fill="#8884d8" name="Users" />
                                <Bar yAxisId="right" dataKey="revenue" fill="#82ca9d" name="Revenue ($)" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 truncate">
                                Total Users
                            </dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                                24,563
                            </dd>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 truncate">
                                Average Session Duration
                            </dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                                4m 32s
                            </dd>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 truncate">
                                Conversion Rate
                            </dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                                3.68%
                            </dd>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;