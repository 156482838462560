import React from 'react';
import { Link } from 'react-router-dom';

function Legal() {
    return (
        <div className="bg-white dark:bg-gray-900 min-h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    Legal Information
                </h1>
                <p className="mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-300 sm:mt-4">
                    Important legal documents and policies for Arrow Cloud Tech users.
                </p>

                <div className="mt-10 space-y-8">
                    <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                Terms of Service
                            </h3>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                Our Terms of Service outline the rules and regulations for the use of Arrow Cloud Tech's services.
                            </p>
                            <div className="mt-5">
                                <Link to="/terms" className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                                    Read Terms of Service <span aria-hidden="true">&rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                Privacy Policy
                            </h3>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                Our Privacy Policy describes how we collect, use, and protect your personal information.
                            </p>
                            <div className="mt-5">
                                <Link to="/privacy" className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                                    Read Privacy Policy <span aria-hidden="true">&rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                Acceptable Use Policy
                            </h3>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                Our Acceptable Use Policy defines the permitted uses and prohibited activities when using our services.
                            </p>
                            <div className="mt-5">
                                <Link to="/acceptable-use" className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                                    Read Acceptable Use Policy <span aria-hidden="true">&rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                DMCA Policy
                            </h3>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                                Our DMCA (Digital Millennium Copyright Act) Policy outlines procedures for copyright infringement notifications.
                            </p>
                            <div className="mt-5">
                                <Link to="/dmca" className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                                    Read DMCA Policy <span aria-hidden="true">&rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-16">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Contact Our Legal Team</h2>
                    <p className="mt-4 text-base text-gray-700 dark:text-gray-300">
                        If you have any legal questions or concerns, please contact our legal team at legal@arrowcloud.tech.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Legal;