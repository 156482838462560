import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { ThemeContext } from '../contexts/ThemeContext';

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'SaaS Development', href: '/saas-development' },
    { name: 'Digital Marketing', href: '/digital-marketing' },
    { name: 'Jobs', href: '/jobs' },
    { name: 'Contact', href: '/contact' },
];

function Navbar() {
    const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);

    return (
        <Disclosure as="nav" className="bg-white dark:bg-gray-800 shadow">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-24"> {/* Increased height to 100px (h-24) */}
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <img className="h-24 w-auto" src="/logo.png" alt="Arrow Cloud Tech" /> {/* Adjust logo size if needed */}
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="hidden md:ml-6 md:flex md:space-x-8">
                                    {navigation.map((item) => (
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            className={({ isActive }) =>
                                                isActive
                                                    ? 'border-blue-500 text-gray-900 dark:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                                                    : 'border-transparent text-gray-500 dark:text-gray-300 hover:border-gray-300 hover:text-gray-700 dark:hover:text-gray-200 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                                            }
                                        >
                                            {item.name}
                                        </NavLink>
                                    ))}
                                </div>
                                <button
                                    onClick={toggleDarkMode}
                                    className="ml-6 p-2 rounded-md text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    {isDarkMode ? (
                                        <SunIcon className="h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MoonIcon className="h-6 w-6" aria-hidden="true" />
                                    )}
                                </button>
                                <div className="-mr-2 flex items-center md:hidden">
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as={NavLink}
                                    to={item.href}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'bg-blue-50 dark:bg-blue-900 border-blue-500 text-blue-700 dark:text-blue-200 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                                            : 'border-transparent text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:hover:text-gray-200 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export default Navbar;