import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, ThemeContext } from './contexts/ThemeContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import SaaSDevelopment from './pages/SaaSDevelopment';
import DigitalMarketing from './pages/DigitalMarketing';
import Jobs from './pages/Jobs';
import Contact from './pages/Contact';
import APIStatus from './pages/APIStatus';
import Analytics from './pages/Analytics';
import Legal from './pages/Legal';
import Press from './pages/Press';
import Partners from './pages/Partners';
import CloudSolutions from './pages/CloudSolutions';
import Privacy from './pages/Privacy';



function AppContent() {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <Router>
        <Navbar />
        <main className="flex-grow bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/saas-development" element={<SaaSDevelopment />} />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/apistatus" element={<APIStatus />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/press" element={<Press />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/cloudsolutions" element={<CloudSolutions />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;