import React from 'react';
import { Link } from 'react-router-dom';
import { MegaphoneIcon, GlobeAltIcon, ChatBubbleBottomCenterTextIcon, EnvelopeIcon, PresentationChartLineIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const services = [
    {
        name: 'Search Engine Optimization (SEO)',
        description: 'Improve your website visibility in search engine results and drive organic traffic.',
        icon: MagnifyingGlassIcon,
    },
    {
        name: 'Social Media Marketing',
        description: 'Engage with your audience and build brand awareness through strategic social media campaigns.',
        icon: GlobeAltIcon,
    },
    {
        name: 'Content Marketing',
        description: 'Develop and distribute valuable, relevant content to attract and retain a clearly defined audience.',
        icon: ChatBubbleBottomCenterTextIcon,
    },
    {
        name: 'Email Marketing',
        description: 'Create personalized email campaigns to nurture leads and drive customer retention.',
        icon: EnvelopeIcon,
    },
    {
        name: 'Pay-Per-Click (PPC) Advertising',
        description: 'Create and manage targeted ad campaigns to drive immediate traffic and conversions.',
        icon: MegaphoneIcon,
    },
    {
        name: 'Analytics and Reporting',
        description: 'Track and analyze your digital marketing efforts to optimize performance and ROI.',
        icon: PresentationChartLineIcon,
    },
];

function DigitalMarketing() {
    return (
        <div className="bg-white dark:bg-gray-900">
            {/* Hero section */}
            <div className="bg-gradient-to-r from-blue-500 to-blue-700 dark:from-blue-700 dark:to-blue-900">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h1 className="text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
                            Digital Marketing
                        </h1>
                        <p className="mt-6 max-w-2xl mx-auto text-xl text-blue-100">
                            Boost your online presence and reach your target audience effectively.
                        </p>
                    </div>
                </div>
            </div>

            {/* Services section */}
            <div className="py-24 bg-white dark:bg-gray-800">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <h2 className="text-base text-blue-600 dark:text-blue-400 font-semibold tracking-wide uppercase">Services</h2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                            Comprehensive Digital Marketing Solutions
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 dark:text-gray-300 lg:mx-auto">
                            Our digital marketing services are designed to help your business thrive in the online world.
                        </p>
                    </div>

                    <div className="mt-20">
                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                            {services.map((service) => (
                                <div key={service.name} className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                                            <service.icon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">{service.name}</p>
                                    </dt>
                                    <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">{service.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="bg-blue-50 dark:bg-blue-900">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white md:text-4xl">
                        <span className="block">Ready to elevate your digital marketing?</span>
                        <span className="block text-blue-600 dark:text-blue-400">Let's create your success story.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <Link
                                to="/contact"
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                            >
                                Get started
                            </Link>
                        </div>
                        <div className="ml-3 inline-flex rounded-md shadow">
                            <Link
                                to="/saas-development"
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50"
                            >
                                Learn about SaaS Development
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Ensure proper spacing at the bottom */}
            <div className="py-12 bg-white dark:bg-gray-900"></div>
        </div>
    );
}

export default DigitalMarketing;